import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import * as API from '../../services/api';

import Form from './Form';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),

    '& .MuiTypography-root': {
      flexGrow: 1,
    },
  },
}));

export default function AdminUsersNew(props) {
  const classes = useStyles();

  const onSubmit = async (values) => {
    await API.createAdminUser(values.email, values.password);
    navigate('/admin_users');
  };

  return (
    <>
      <Paper className={classes.nav}>
        <Typography variant="h6">New Admin User</Typography>
      </Paper>
      <Form isNew={true} onSubmit={onSubmit} />
    </>
  );
}
