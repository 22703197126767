import React from 'react';
import Layout from '../components/layout/Layout';
import AdminUsersNew from '../components/admin_users/New';

export default (props) => {
  return (
    <Layout page="admin_users">
      <AdminUsersNew />
    </Layout>
  );
};
